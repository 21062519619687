<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <router-view />
  </div>
</template>
<style>
html{
color:black!important;
}
</style>
<script>
import useAppConfig from '@core/app-config/useAppConfig'

import axios from 'axios'

export default {
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
  mounted() {

  },
  methods: {
    checkAuth() {
      const userToken = this.$cookies.get('userToken')
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECK_TOKEN}`, { headers })
      .then(response => {
        if (response.data.success !== true) {
          this.$router.replace({ name: 'auth-login' })
        }
        })
      .catch(e => {
        console.log(e)
      })
    },
  },
}
</script>
